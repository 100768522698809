import React from 'react'
import {PopupCross} from "../../hooks/popup/components/PopupCross";

import rustIcon from './../../assets/images/rust.png'
import csgoIcon from './../../assets/images/csgo.png'
import creditCard from './../../assets/images/creditCard.png'
import {useDispatch} from "react-redux";
import {setPopup} from '../../redux/toolkitSlice';
import {Translate} from "../translate/Translate";

interface IAddCashProps {

}

export const AddCash: React.FC<IAddCashProps> = () => {

    const dispatch = useDispatch()

    return (
        <>
            <h2>
                <Translate>add_balance</Translate>
            </h2>
            <PopupCross/>
            <div className="popup-add-coins__var">
                <button onClick={_ => dispatch(setPopup('popup-add-coins-skins'))} className="game__item">
                    <p>
                        <Translate>pay_skin</Translate>
                    </p>
                    <br/>
                    <img src={rustIcon} alt="Game"/>
                    <img src={csgoIcon} alt="Game"/>
                </button>
            </div>
            <div className="popup-add-coins__game">
                <button className="var__item" onClick={_ => dispatch(setPopup("popup-add-coins-balance popup-add-coins-balance-kzt"))}>
                    <p>
                        <Translate>card_kzt</Translate>
                    </p>
                    <br/>
                    <br/>
                    <img src={creditCard} alt="Game"/>
                </button>
                <button className="var__item" onClick={_ => dispatch(setPopup("popup-add-coins-balance popup-add-coins-balance-uah"))}>
                    <p>
                        <Translate>card_uah</Translate>
                    </p>
                    <br/>
                    <br/>
                    <img src={creditCard} alt="Game"/>
                </button>
                <button className="var__item" onClick={_ => dispatch(setPopup("popup-add-coins-balance popup-add-coins-balance-rub"))}>
                    <p>
                        <Translate>card_rub</Translate>
                    </p>
                    <br/>
                    <br/>
                    <img src={creditCard} alt="Game"/>
                </button>
            </div>
        </>
    )
}
