import React, { useRef, useState } from 'react'
import filter from './../../../../assets/images/filter.svg'
import { FilterStyle } from './filter.styled'
import { useRightFilter } from '../../../../hooks/rightFilter'
import {Translate} from "../../../translate/Translate";

interface IFilterProps {
    isNotHaveRarity?: boolean
}

export const Filter: React.FC<IFilterProps> = ({isNotHaveRarity}) => {

    const {radioBlock, handleSelect, handleChange, labelSelect} = useRightFilter()

    return (
        <FilterStyle>
            <label ref={radioBlock} onClick={_ => handleSelect('price')} className={"filter__item" + (labelSelect === 'price' ? " _active" : "")}>
                <span>
                    <Translate>sort_by_price</Translate>
                </span>
                <input type="checkbox" name="upDown" onChange={e => handleChange(e)} />
                <img src={filter} alt="filter" />
            </label>
            {!isNotHaveRarity && <label ref={radioBlock} onClick={_ => handleSelect('rarity')}
                    className={"filter__item" + (labelSelect === 'rarity' ? " _active" : "")}>
                <span>
                    <Translate>sort_by_rarity</Translate>
                </span>
                <input type="checkbox" name="upDown" onChange={e => handleChange(e)}/>
                <img src={filter} alt="filter"/>
            </label>}
        </FilterStyle>
    )
}
