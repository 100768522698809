import { styled } from 'styled-components';

export const AppStyled = styled.div`

  .skins__inner {
    min-height: 385px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .skins__block {
    width: 100%;
    margin-top: 0;
  }

`
