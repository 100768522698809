import React from 'react'
import search from './../../../../assets/images/search.svg'
import {useTranslation} from "react-i18next";

interface ISearchProps {
    searchValue: string
    setSearchValue: any
}

export const Search: React.FC<ISearchProps> = ({searchValue, setSearchValue}) => {

    const {t} = useTranslation()

    return (
        <div className="postamat__search">
            <input type="text" value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder={`${t('search')}`} />
            <button>
                <img src={search} alt="Search" />
            </button>
        </div>
    )
}
