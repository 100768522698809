import React, { useContext, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setNotice} from '../../../redux/toolkitSlice'

import ArrToSend from './../../../assets/images/send-message.svg'
import smile from './../../../assets/images/smile-1.png'
import { ChatWsContext } from '../../../context/chatWsContext'
import getCookies from "../../../functions/getCookie";
import {IUser} from "../../../model";
import {useTranslation} from "react-i18next";

interface IChatFormProps {
    setIsOpenSmiles: any
}

export const ChatForm: React.FC<IChatFormProps> = ({ setIsOpenSmiles }) => {

    const [messageValue, setMessageValue] = useState('')
    const [isTimeout, setIsTimeout] = useState(true)

    const dispatch = useDispatch()
    const ws: any = useContext(ChatWsContext);

    const user: IUser = useSelector((state: any) => state.toolkit.user)

    const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if(!isTimeout) return dispatch(setNotice("waitBeforeSendMessage"));

        setIsTimeout(false)

        setTimeout(() => {
            setIsTimeout(true)
        }, 5500)

        if(!Object.keys(user).length) return dispatch(setNotice("forChattingAuth"))

        if (!messageValue.length) return;

        if(!getCookies('access_token_rust')) {
            return dispatch(setNotice('forChattingAuth'))
        }

        if (ws && ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify({
                "type": "send_message",
                "data": {
                    "message": messageValue
                }
            }));
        }

        setMessageValue('')

        setTimeout(() => {
            document.querySelector('.section-right__item')?.scrollTo(0, 9999999)
        }, 100)
    }

    const checkValueLength = (messageValue.length >= 150 ? " _red" : "")

    const {t} = useTranslation()

    return (
        <form onSubmit={handleSendMessage} className="section-right__bottom" action="#">
            <label className="textarea">
                <input maxLength={150} value={messageValue} onChange={event => setMessageValue(event.target.value)} placeholder={t("chat_message_text")} />
                <span className={"maxl" + checkValueLength}>{messageValue.length}/150</span>
            </label>
            <div className="smiles" onClick={_ => setIsOpenSmiles((prev: any) => !prev)}>
                <img src={smile} alt="Smile" />
            </div>
            <button type='submit' className="send">
                <img src={ArrToSend} alt="Ico" />
            </button>
        </form>
    )
}
