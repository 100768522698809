import React from 'react'
import { useSteamLogin } from '../../../../hooks/steamLogin'
import steam from './../../../../assets/images/steam.svg'
import { SteamLoginStyle } from './steamLogin.styled'
import {Translate} from "../../../translate/Translate";

interface ISteamLoginProps {

}

export const SteamLogin: React.FC<ISteamLoginProps> = () => {

    const {auth_params} = useSteamLogin()

    return (
        <SteamLoginStyle href={"http://steamcommunity.com/openid/login?" + new URLSearchParams(auth_params).toString()} className={"buttonToLogin"}>
            <span>
                <Translate>entry_v2</Translate>
            </span>
            <img src={steam} alt="Login" />
        </SteamLoginStyle>
    )
}
