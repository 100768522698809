import React, {useContext, useEffect} from 'react'
import lineAirdrop from "../../../../../assets/images/line-for-right.svg";
import coins from "../../../../../assets/images/header__coins.svg";
import {AirdropSocketContext} from "../../../../../App";
import {useAirdropTimer} from "../../../../../hooks/airdropTimer";
import {prettyCoinValues} from "../../../../../functions/prettyCoinValues";
import {useTranslation} from "react-i18next";
import {Translate} from "../../../../translate/Translate";

interface IAirdropInfoBlockProps {

}

export const AirdropInfoBlock:React.FC<IAirdropInfoBlockProps> = () => {

    const airdropWsMessages: any = useContext(AirdropSocketContext)

    const {seconds, milliseconds} = useAirdropTimer(0)

    const {t} = useTranslation()

    const textBlock: any = {
        "start": `${t("waiting_players")}`,
        "waiting": `${t("before_fly")}:`,
    }

    const fixTimer: any = {
        "waiting": 30,
        "process": 30,
        "drop": 5,
        "ended": 10,
        "prepare": 5,
        "start": 1,
    }

    return (
        <div className="airdrop__block">
            <div className="airdrop__fly">
                <div className="fly__top">
                    <p>
                        {
                            textBlock[airdropWsMessages?.airdrop?.game_state]
                        }
                    </p>
                    <div className="timer">
                        <div className="min">
                            <span>
                                {
                                    seconds < 10 ? "0" + seconds : seconds
                                }
                            </span>
                        </div>
                        <div className="sec">
                            <small className="dot">.</small>
                            <span>
                                {
                                    milliseconds < 10 ? "0" + milliseconds : milliseconds
                                }
                            </span>
                        </div>
                    </div>
                </div>

                {/*.section-right__airdrop .airdrop__timer-to-ready .line_done:after*/}
                <div className={"fly__bottom" + (airdropWsMessages?.airdrop?.game_state === "process" ? " fly__bottom_plane" : "")}>
                    <div className="fly__timer">
                        <img src={lineAirdrop} alt="Ico"/>
                        <div className="line">
                            <div className="line_done" style={{width: airdropWsMessages?.airdrop?.game_state === "process" ? (-(seconds * 100 / fixTimer[airdropWsMessages?.airdrop?.game_state]) + 100) + "%" : (seconds * 100 / fixTimer[airdropWsMessages?.airdrop?.game_state]) + "%"}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="airdrop__bank">
                <p>
                    <Translate>in_bank</Translate>
                </p>
                <div className="coins">
                    <img src={coins} alt="Ico"/>
                    <span>
                        {
                            prettyCoinValues(airdropWsMessages?.airdrop?.bank)
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}
