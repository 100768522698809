import React, { useEffect, useState } from 'react'
import { NoticeStyle } from './Notice.styled'

import errorIcon from './../../assets/images/status-error.svg'
import errorRedIcon from './../../assets/images/error-red.svg'
import checkIcon from './../../assets/images/green-check.svg'
import { INotice } from '../../model'
import { useDispatch, useSelector } from 'react-redux'
import { setNotice } from '../../redux/toolkitSlice'
import {Translate} from "../translate/Translate";

interface INoticeProps {

}

export const Notice: React.FC<INoticeProps> = () => {

    const noticeSlug = useSelector((state: any) => state.toolkit.notice)
    const [isShow, setIsShow] = useState(false)
    const dispatch = useDispatch()

    const notices: INotice[] = [
        {
            slug: 'forChattingAuth',
            text: <Translate>for_message_auth</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'beforeYouNeedAuth',
            text: <Translate>auth_for_action</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'writeTrueTradeLink',
            text: <Translate>write_true_trade_link</Translate>,
            icon: errorRedIcon,
        },
        {
            slug: 'recycled',
            text: <Translate>processor_done</Translate>,
            icon: checkIcon,
        },
        {
            slug: 'addedToCart',
            text: <Translate>added_to_cart</Translate>,
            icon: checkIcon,
        },
        {
            slug: 'forBuyingAuth',
            text: <Translate>for_buy_items_auth</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'youHaveAlreadyAddedItemToCart',
            text: <Translate>item_already_in_cart</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'dontHaveMoney',
            text: <Translate>you_dont_have_enough_money</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'needSetAllSleepers',
            text: <Translate>you_need_place_all_bags</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'sleepersBuoghtSuccess',
            text: <Translate>success_to_buy_bags</Translate>,
            icon: checkIcon,
        },
        {
            slug: 'youHaveAlreadyHaveGame',
            text: <Translate>already_have_a_game</Translate>,
            icon: errorRedIcon,
        },
        {
            slug: 'youHaveBlockedToChat',
            text: <Translate>block_chat</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'toLowValue',
            text: <Translate>amount_smaller</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'toMoreValue',
            text: <Translate>amount_larger</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'airdropNotWork',
            text: <Translate>not_enable_airdrop</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'payingNotWork',
            text: <Translate>not_enable_payment</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'withdrawNotWork',
            text: <Translate>not_enable_withdraw</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'fightNotWork',
            text: <Translate>not_enable_fight</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'oneOrMoreCantBuy',
            text: <Translate>nothing_to_buy</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'thisItemYouCantBuy',
            text: <Translate>item_not_available</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'fightItemAlreadyProcess',
            text: <Translate>game_already_start</Translate>,
            icon: errorIcon,
        },
        {
            slug: 'cant_refresh_yet',
            text: <Translate>cant_refresh_yet</Translate>,
            icon: errorIcon,
        },
        {
            slug: "amount_smaller",
            text: <Translate>amount_smaller</Translate>,
            icon: errorIcon,
        },
        {
            slug: "amount_larger",
            text: <Translate>amount_larger</Translate>,
            icon: errorIcon,
        },
        {
            slug: "waitBeforeSendMessage",
            text: <Translate>waiting_message</Translate>,
            icon: errorIcon,
        },
    ]

    useEffect(() => {
        if(noticeSlug)

        setTimeout(() => {
            setIsShow(true)

            setTimeout(() => {
                setIsShow(false)

                setTimeout(() => {
                    dispatch(setNotice(''))
                }, 300)

            }, 1250)
        }, 5)
    }, [noticeSlug])

    return (
        <NoticeStyle className={isShow ? " active" : ""}>
            {
                notices?.map((item: INotice) => {
                    if (item.slug === noticeSlug) {
                        return <li key={item.slug} className={"notice__item"}>
                            <span>
                                {item.text}
                            </span>
                            <img src={item.icon} alt="" />
                        </li>
                    }
                })
            }
        </NoticeStyle>
    )
}
