import React, {useEffect, useState} from 'react'
import coin from './../../../../../assets/images/header__coins.svg'
import cross from './../../../../../assets/images/cross.svg'
import backIcon from './../../../../../assets/images/arr-td.svg'
import {useDispatch, useSelector} from 'react-redux'
import {IProduct, ISiteSettings, IUser} from '../../../../../model'
import {
    removeItemFromCart,
    setNotice,
    setSound,
    setUserBalance,
    setUserInventory
} from '../../../../../redux/toolkitSlice'
import axios from 'axios'
import {getApiLink} from '../../../../../functions/getApiLink'
import {getBearer} from '../../../../../functions/getBearer'
import {getShop} from '../../../../../api/getShopItems'
import {LoadingStyled} from '../../../../loading/loading.styled'
import {prettyCoinValues} from "../../../../../functions/prettyCoinValues";
import { Translate } from '../../../../translate/Translate'

interface IRightShopCartProps {
    isCartOpen: any
    setIsCartOpen: any
    setIsBoughtSuccess: any
}

export const RightShopCart: React.FC<IRightShopCartProps> = ({isCartOpen, setIsCartOpen, setIsBoughtSuccess}) => {

    const cart = useSelector((state: any) => state.toolkit.shopCart)
    const user: IUser = useSelector((state: any) => state.toolkit.user)
    const siteSettings: ISiteSettings = useSelector((state: any) => state.toolkit.siteSettings)

    const dispatch = useDispatch()
    const [isBuying, setIsBuying] = useState(false)
    const [price, setPrice] = useState<number>(0)

    useEffect(() => {
        setPrice(0)
        cart.map((item: IProduct) => setPrice(prev => prev + item.price.value))
        setPrice(prev => prev + (prev * siteSettings.pay_skin_commission / 100))
    }, [cart])

    const handleBuy = () => {

        console.log(user?.balance, price)
        if (!(user?.balance && +user?.balance >= price)) return dispatch(setNotice("dontHaveMoney"))

        setIsBuying(true)

        getBearer({type: 'post'});
        axios.post(getApiLink('api/basket/buy')).then(({data}) => {
            dispatch(setUserBalance(data.balance))
            dispatch(setUserInventory(cart))
            dispatch(removeItemFromCart('all'));
            dispatch(setSound('sound5'));
            getShop({dispatch});
            setIsBoughtSuccess(true);
            setIsBuying(false)
        })
    }

    const handleDeleteItemFromCart = (data: IProduct) => {
        getBearer({type: 'post'});
        axios.post(getApiLink(`api/basket/remove?item_id=${data.id}`));
        dispatch(removeItemFromCart(data));
    }

    return (
        <div className={"section-right__cart" + (isCartOpen ? " section-right__cart_active" : "")}>
            <h2>
                <Translate>cart</Translate>
            </h2>
            <div className="cart__list">

                {
                    cart.map((item: IProduct) =>
                        <div key={item.id} className="cart__item">
                            <div className={"clothes__cool clothes__cool_" + item.rarity}/>
                            <div className="item__photo">
                                <img src={item.image} alt="Ico"/>
                            </div>
                            <div className="item__info">
                                <div className="item__name">
                                    {item.title}
                                </div>
                                <div className="item__price">
                                    <img src={coin} alt="Coins"/>
                                    <span>
                                        {prettyCoinValues(item.price.value)}
                                    </span>
                                </div>
                            </div>
                            <div onClick={_ => handleDeleteItemFromCart(item)} className="item__delete">
                                <img src={cross} alt="Delete"/>
                            </div>
                        </div>
                    )
                }

            </div>
            <div className="buttons">
                <button onClick={_ => setIsCartOpen(false)} className="buttons__back">
                    <img src={backIcon} alt="Ico"/>
                    <span>
                        <Translate>back</Translate>
                    </span>
                </button>


                <button onClick={handleBuy} className="buttons__buy">
                    {!isBuying ? <span><Translate>buy</Translate></span> :
                        <LoadingStyled className="load">
                            <div className="line"/>
                            <div className="line"/>
                            <div className="line"/>
                        </LoadingStyled>}
                </button>

            </div>
        </div>
    )
}
