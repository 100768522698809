import React, {FormEvent, useContext, useEffect, useState} from 'react'
import {PopupCross} from "../../hooks/popup/components/PopupCross";
import axios from "axios";
import {getApiLink} from "../../functions/getApiLink";
import {PopupsContext} from "../../context/popupsContext";
import {useDispatch} from "react-redux";
import {serUserTradeLink, setPopup} from "../../redux/toolkitSlice";
import {getBearer} from "../../functions/getBearer";
import {Translate} from "../translate/Translate";

interface ITradeLinkChangeProps {

}

export const TradeLinkChange: React.FC<ITradeLinkChangeProps> = () => {

    const [tradeLink, setTradeLink] = useState('')
    const setIsOpen: any = useContext(PopupsContext)
    const dispatch = useDispatch()
    const [isError, setIsError] = useState(false)

    const handleChangeTradeLink = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsError(false)

        getBearer({type: "post"})
        axios.post(getApiLink('api/user/change_trade_link/'), {"trade_link": tradeLink}).then(({data}) => {

            if(data.status) {
                setIsOpen(false)

                dispatch(serUserTradeLink(tradeLink))

                setTimeout(() => {
                    dispatch(setPopup(''))
                }, 300)
            } else {
                setIsError(true)
            }
        }).catch(er => {
            console.log(er)
        })
    }

    return (
        <>
            <h2>
                <Translate>trade_link</Translate>
            </h2>
            <p>
                <Translate>write_your_trade_link</Translate>
            </p>
            <PopupCross/>
            <form onSubmit={e => handleChangeTradeLink(e)}>
                <input className={"trade-link__input"} style={{border: isError ? "1px solid red" : ""}} type="text" required onChange={e => setTradeLink(e.target.value)} value={tradeLink}/>
                <div className="trade-link__buttons">
                    <button>
                        <Translate>save</Translate>
                    </button>
                    <a target="_blank"
                       href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url">
                        <Translate>where_trade_link_get</Translate>
                    </a>
                </div>
            </form>
        </>
    )
}
