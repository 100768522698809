import React, {useEffect} from 'react'
import {IUser, IUserGames, IUserHistoryFight} from "../../../../model";
import victory from "../../../../assets/images/victory.svg";
import fail from "../../../../assets/images/fail.svg";
import no_photo from "../../../../assets/images/non-photo.png";
import {NavLink} from "react-router-dom";
import coin from "../../../../assets/images/header__coins.svg";
import { getApiLink } from '../../../../functions/getApiLink';
import shield from "../../../../assets/images/shield.svg";
import {setPopup, setPopupData} from "../../../../redux/toolkitSlice";
import {useDispatch} from "react-redux";
import {prettyCoinValues} from "../../../../functions/prettyCoinValues";
import {Translate} from "../../../../components/translate/Translate";

interface ITableForCrateProps {
    tableValue: string,
    tableData: IUserGames,
    gameData: IUserHistoryFight[]
    user: IUser
}

export const TableForCrate: React.FC<ITableForCrateProps> = ({ tableValue, tableData, gameData, user }) => {

    const dispatch = useDispatch()

    const handleOpenPF = (dataItem: any) => {
        dispatch(setPopup('popup-fair-game'))
        dispatch(setPopupData(dataItem))
    }

    return (
        <div className={"tabs__item tabs__item-fight" + (tableValue.toLowerCase().includes(tableData?.slug?.toLowerCase()) ? " tabs__item_active" : "")}>
            <div className="table">
                <div className="thead">
                    <div className="tr">
                        <div className="td">
                            <Translate>crate</Translate>
                        </div>
                        <div className="td">
                            <Translate>item</Translate>
                        </div>
                        <div className="td">
                            <Translate>table_thead_pf</Translate>
                        </div>
                        <div className="td">
                            <Translate>cost</Translate>
                        </div>
                    </div>
                </div>
                <div className="tbody">

                    {
                        gameData?.map((item: any) => {

                            const crate = item?.crate
                            const crateItem = item?.item?.item?.image
                            const amount = item?.amount
                            const smallHash = item.random_hash.slice(0, 5) + '...' + item.random_hash.slice(item.random_hash.length - 6)

                            return (
                                <div key={item.id} className="tr">
                                    <div className="td">
                                        <ul className="list-players">
                                            <li>
                                                <img src={getApiLink(crate.icon)} alt=""/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="td">
                                        <ul className="list-players">
                                            <li>
                                                <img src={crateItem} alt=""/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="td">
                                        <div className="shield">
                                            <img src={shield} alt="Shield" />
                                            <a onClick={_ => handleOpenPF(item)}>
                                                {smallHash}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="td">
                                        <div className="td__coins">
                                            <img src={coin} alt="Ico" />
                                            <span>
                                                {
                                                    prettyCoinValues(amount)
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}
