import React from 'react'
import { NotFoundStyle } from './notFound.styled'
import { NavLink } from 'react-router-dom'
import {Translate} from "../../components/translate/Translate";

interface INotFoundProps {

}

export const NotFound: React.FC<INotFoundProps> = () => {

    return (
        <NotFoundStyle className="tech-work">
            <h1>
                <Translate>this_page_not_found</Translate>
            </h1>
            <p>
                <Translate>this_page_not_found_text</Translate>
            </p>
            <NavLink to={"/"}>
                <Translate>back_to_main_page</Translate>
            </NavLink>
        </NotFoundStyle>
    )
}
