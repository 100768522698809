import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import coins from './../../../../../assets/images/header__coins.svg'
import {store} from "../../../../../redux";
import {IUser} from "../../../../../model";
import {prettyCoinValues} from "../../../../../functions/prettyCoinValues";
import {setSound} from "../../../../../redux/toolkitSlice";
import {Translate} from "../../../../translate/Translate";

interface IAirdropBagsProps {
    setBags: any
    handleBuyBags: any
}

export const AirdropBags: React.FC<IAirdropBagsProps> = ({setBags, handleBuyBags}) => {

    const [countOfBags, setCountOfBags] = useState(0)
    const [costOfBags, setCostOfBags] = useState(0)

    const dispatch = useDispatch()

    const userInfo: IUser = useSelector((state: any) => state.toolkit.user)

    const bagsArray = Array.apply('', Array(9))
    const costOneBag = 50

    useEffect(() => {
        if(countOfBags === 0) return

        setBags(countOfBags)
        dispatch(setSound('sound12'))
    }, [countOfBags])

    const isCanBuy = userInfo.balance ? userInfo.balance >= costOfBags : false

    return (
        <div className="airdrop__sleepers">
            <h3>
                <Translate>count_of_bags</Translate>:</h3>
            <ul>

                {
                    bagsArray.map((item, index) =>
                        <li key={index} className={countOfBags === index + 1 ? "button_active" : ""}
                            onClick={_ => {
                                setCountOfBags(index + 1)
                                setCostOfBags((index + 1) * costOneBag)
                            }}>
                            <button>{index + 1}</button>
                        </li>
                    )
                }

            </ul>

            <button onClick={_ => handleBuyBags(!(isCanBuy && countOfBags > 0))} className={"sleepers__buy" + (isCanBuy && countOfBags > 0 ? " move__submit" : "")}>

                {countOfBags > 0 ? <>
                    <span>
                        <Translate>buy</Translate>
                    </span>
                    <img src={coins} alt="Coin"/>
                    <span>
                        {
                            prettyCoinValues(costOfBags)
                        }
                    </span>
                </> : <span>
                    <Translate>choose_count_of_bags</Translate>
                </span>}
            </button>
        </div>
    )
}
