import React, {useContext, useEffect} from 'react'
import coins from './../../assets/images/header__coins.svg'
import {PopupCross} from "../../hooks/popup/components/PopupCross";
import {IFightItem, IProduct} from "../../model";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {getApiLink} from "../../functions/getApiLink";
import {setPopup, setUserBalance} from "../../redux/toolkitSlice";
import {PopupsContext} from "../../context/popupsContext";
import {useNavigate} from "react-router";
import {prettyCoinValues} from "../../functions/prettyCoinValues";
import {getBearer} from "../../functions/getBearer";
import getCookies from "../../functions/getCookie";
import {RefreshToken} from "../../api/refreshToken";
import {Translate} from "../translate/Translate";

interface IStartFightCashProps {

}

export const StartFightCash: React.FC<IStartFightCashProps> = () => {

    const fightItemData: IFightItem = useSelector((state: any) => state.toolkit.fightItemData)
    const setIsOpen: any = useContext(PopupsContext)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleStartGame = () => {

        getBearer({type: "post"})
        axios.post(getApiLink("api/fight/room/join?game_id="+fightItemData.id), {
            coins: fightItemData.first_player.coins,
        }).then(({data}) => {
            if(data?.status === false) return;

            setIsOpen(false)
            setTimeout(() => {
                dispatch(setPopup(''))
            }, 300)

            dispatch(setUserBalance({
                sum: true,
                money: -data.first_player.coins
            }))

            navigate('/fight/'+fightItemData.id)

        }).catch(er => {
            er?.response?.status === 401 && RefreshToken({dispatch, handleStartGame})
        })

    }

    return (
        <>
            <h2>
                <Translate>room</Translate>
            </h2>
            <p className="subtitle"><Translate>type_fight</Translate> <span>
                <Translate>type_fight_coins</Translate>
            </span>
            </p>
            <PopupCross/>
            <div className="popup-entry-coins__info">
                <p>
                    <Translate>bet_in_this_room</Translate>
                </p>
                <div className="info__coins">
                    <img src={coins} alt="Ico"/>
                    <span>
                        {prettyCoinValues(fightItemData.first_player.coins)}
                    </span>
                </div>
            </div>
            <button onClick={handleStartGame}>
                <Translate>place_a_bet</Translate>
            </button>
        </>
    )
}
