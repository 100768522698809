import React, { useEffect } from 'react'
import blueCheck from './../../../../../assets/images/blue-check.svg'
import {Translate} from "../../../../translate/Translate";

interface IAirdropMemberProps {

}

export const AirdropMember:React.FC<IAirdropMemberProps> = () => {

    return (
        <div className="airdrop__member">
            <div className="member__ico">
                <img src={blueCheck} alt="Ico"/>
            </div>
            <span>
                <Translate>you_are_a_member_of_airdrop</Translate>
            </span>
        </div>
    )
}
